import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";


export const fetchOwners = createAsyncThunk('fetchOwners', async ({ page, search }) => {
    const resp = await api.get(`/owners?page=${page}&keyword=${search}`);
    console.log("test", resp.data.data.owners.data);
    return resp.data.data.owners;
});

export const delOwner = createAsyncThunk('delOwner', async (id) => {
    const resp = await api.delete(`/owners/${id}`);
    console.log("test", resp.data.data.owners.data);
    return resp.data;
})

export const addedOwner = createAsyncThunk('addedOwner', async (data) => {
    const resp = await api.post(`/owners`, data);
    console.log("test", resp.data.data.owners.data);
    return resp.data;
})

export const editOwner = createAsyncThunk('editOwner', async (id) => {
    const response = await api.get(`/owners/${id}`);
    return response.data.data.owner;
})

export const updateOwner = createAsyncThunk('updateOwner', async ({ id, formData }) => {
    try {
        const response = await api.post(`/owners/${id}`, formData);
        return response.data.data;
    } catch (error) {
        console.log(error.response.data, 'errroor');
        return error.response.data;
    }
});


const couponsSlice = createSlice({
    name: "owners",
    initialState: {
        data: null,
        owner: [],
        total: 0,
        errors: null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOwners.fulfilled, (state, action) => {
            console.log(action.payload, 'addcase');
            state.owner = action.payload
            state.errors = null;
        })
        builder.addCase(delOwner.fulfilled, (state, action) => {
            console.log(action.payload.messages, 'deletecase');
            state.data = action.payload;
        })
        builder.addCase(addedOwner.fulfilled, (state, action) => {
            state.coupon = action.payload
        })
        builder.addCase(editOwner.fulfilled, (state, action) => {
            const ownerData = action.payload;
            state.owner = ownerData;
        })
        builder.addCase(updateOwner.fulfilled, (state, action) => {
            state.owner = action.payload;
            state.errors = null;
        });
        builder.addCase(updateOwner.rejected, (state, action) => {
            state.errors = action.payload;
        });

    },
});

export default couponsSlice.reducer;
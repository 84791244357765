import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";

export const fetchCoupons = createAsyncThunk('fetchCoupons', async ({ page, search, shop }) => {
    const resp = await api.get(`/coupons?page=${page}&keyword=${search}&shop_name=${shop}`);
    console.log(resp.data.data, "test coupon");
    return resp.data.data.coupons;
});

export const delCoupon = createAsyncThunk('delCoupon', async (id) => {
    const resp = await api.delete(`/coupons/${id}`);
    return resp.data;
})

export const addedCoupon = createAsyncThunk('addedCoupon', async (data) => {
    try {
        const resp = await api.post(`/coupons`, data);
        return resp.data.data;
    } catch (error) {
        console.log()
        return error.response.data;
    }
});

export const editCoupon = createAsyncThunk('editCoupon', async (id) => {
    const response = await api.get(`/coupons/${id}`);
    return response.data.data.coupon;
})

export const updateCoupon = createAsyncThunk('updateCoupon', async ({id, data}) => {
    try {
        const response = await api.post(`/coupons/${id}`, data);
        return response.data.data;
    } catch (error) {
        return error.response.data;
    }
});

export const addShops = createAsyncThunk('addShops', async (id) => {
    const response = await api.get(`/coupons/shops`);
    console.log("test shops add time", response.data.data.shops)
    return response.data.data.shops;
})


const couponsSlice = createSlice({
    name: "coupons",
    initialState: {
        data: null,
        coupons: [],
        shops: [],
        per_page: 0,
        total: 0,
        errors: null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCoupons.fulfilled, (state, action) => {
            state.coupons = action.payload.data;
            state.total = action.payload.total;
            state.per_page = action.payload.per_page;
            state.errors = null;
        })
        builder.addCase(addShops.fulfilled, (state, action) => {
            state.shops = action.payload;
        })
        builder.addCase(delCoupon.fulfilled, (state, action) => {
            state.data = action.payload;
        })
        builder.addCase(addedCoupon.fulfilled, (state, action) => {
                state.coupons = action.payload;
                state.errors = null;
            })
        builder.addCase(addedCoupon.rejected, (state, action) => {
            state.errors = action.payload;
        });
        builder.addCase(editCoupon.fulfilled, (state, action) => {
            const couponData = action.payload;
            state.viewCoupon = couponData;
        })
        builder.addCase(updateCoupon.fulfilled, (state, action) => {
            state.coupons = action.payload;
            state.errors = null;
        })
        builder.addCase(updateCoupon.rejected, (state, action) => {
            state.errors = action.payload;
        });

    },
});

export default couponsSlice.reducer;
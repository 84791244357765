import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./slice/todo";
import customerReducer from "./slice/customers";
import couponsReducer from "./slice/coupons";
import ownerReducer from "./slice/owners";
import deliverymanReducer from "./slice/deliverymans";
import orderReducer from './slice/Orders'
import shopsReducer from './slice/shops'
import pricesReducer from './slice/prices'
import reportReducer from "./slice/report";

export const store = configureStore({
    reducer: {
        todo: todoReducer,
        customer: customerReducer,
        coupons: couponsReducer,
        owner: ownerReducer,
        deliveryman: deliverymanReducer,
        order: orderReducer,
        shops: shopsReducer,
        prices: pricesReducer,
        report: reportReducer,
    }
})
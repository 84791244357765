import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios"

export const fetchCustomer = createAsyncThunk('fetchCustomer', async ({ page, search }) => {
    const resp = await api.get(`/customers?page=${page}&keyword=${search}`);
    console.log("test", resp.data.data.customers);
    return resp.data.data.customers;
});
export const editCustomer = createAsyncThunk('editCustomer', async (id) => {
    const response = await api.get(`/customers/${id}`);
    return response.data.data.customer;
})

export const viewCustomer = createAsyncThunk('viewCustomer', async (id) => {
    const response = await api.get(`/customers/${id}`);
    return response.data.data.customer;
})
export const viewCustomerOrders = createAsyncThunk('viewCustomerOrders', async (id) => {
    const response = await api.get(`/orders/${id}`);
    return response.data.data.order;
})
export const customerOrders = createAsyncThunk('customerOrders', async ({ id, page, search, findStatus }) => {
    const response = await api.get(`/customers/${id}/orders?page=${page}&keyword=${search}&status_name=${findStatus}`);
    console.log("customerOrders___", response.data.data.orders.data)
    return response.data.data.orders;
})
export const updateCustomer = createAsyncThunk('updateCustomer', async ({ id, formData }) => {
    try {
        const response = await api.post(`/customers/${id}`, formData);
        console.log("updateCustomer slice data", response.data.data.customer);
        return response.data.data;
    } catch (error) {
        console.log("updateCustomer error", error.response.data.errors)
        return error.response.data;
    }
});

// export const editcustomerOrder = createAsyncThunk('editcustomerOrder', async (order_id) => {
//     // console.log("shop_id", shop_id)
//     // console.log("order_id", order_id)
//     const response = await api.get(`/orders/${order_id}`);
//     console.log("editcustomerOrder 41", response.data.data.order);
//     return response.data.data.order;
// })


export const updateCustomerOrder = createAsyncThunk('updateCustomerOrder', async ({ id, data }) => {
    try {
        const response = await api.post(`/orders/${id}`, data);
        console.log("updateShopOrder33", response.data)
        return response.data.data;
    } catch (error) {
        console.log("updateCustomerOrdererror33", error.response.data.errors)
        return error.response.data.errors;
    }
});




const todoSlice = createSlice({
    name: "customers",
    initialState: {
        data: null,
        customers: [],
        customerorder: [],
        total: 0,
        customerorderTotal: 0,
        orders: [],
        errors: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomer.fulfilled, (state, action) => {
            // state.data = action.payload;
            state.customers = action.payload
            state.errors = null;
        })
        builder.addCase(editCustomer.fulfilled, (state, action) => {
            const customerView = action.payload;
            state.viewCustomer = customerView;
        })
        builder.addCase(viewCustomer.fulfilled, (state, action) => {
            const customerView = action.payload;
            state.viewCustomer = customerView;
        })
        builder.addCase(viewCustomerOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
        })
        builder.addCase(updateCustomer.fulfilled, (state, action) => {
            // state.data = action.payload;
            state.customers = action.payload;
            state.errors = null;
        });
        builder.addCase(updateCustomer.rejected, (state, action) => {
            state.errors = action.payload;
        });
        builder.addCase(customerOrders.fulfilled, (state, action) => {
            state.customerorder = action.payload
            state.errors = null;
            // state.orders = action.payload;
            state.customerorderTotal = action.payload.total;
        });
        // builder.addCase(editcustomerOrder.fulfilled, (state, action) => {
        //     state.customerorder = action.payload;
        //     console.log( "state", action);
        // })
        builder.addCase(updateCustomerOrder.fulfilled, (state, action) => {
            state.data = action.payload;
            state.customers = action.payload;
            state.errors = null;
        });
        builder.addCase(updateCustomerOrder.rejected, (state, action) => {
            state.errors = action.payload;
        });
    },
});

export default todoSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";


export const fetchShops = createAsyncThunk('fetchShops', async ({ page, search }) => {
    const resp = await api.get(`/shops?page=${page}&keyword=${search}`);
    return resp.data.data.shops;
});

export const delShop = createAsyncThunk('delShop', async (id) => {
    const resp = await api.delete(`/shops/${id}`);
    return resp.data;
})
export const shopOrders = createAsyncThunk('shopOrders', async ({ id, page, search, findStatus }) => {
    const response = await api.get(`/shops/${id}/orders?page=${page}&keyword=${search}&status_name=${findStatus}`);
    console.log("reducer shoporder", response.data.data);
    return response.data.data.shopOrderList;
})

// export const addedOwner = createAsyncThunk('addedOwner', async (data) => {
//     const resp = await api.post(`/owners`, data);
//     return resp.data;
// })

export const editShop = createAsyncThunk('editShop', async (id) => {
    const response = await api.get(`/shops/${id}`);
    return response.data.data.shop;
})


export const editShopOrder = createAsyncThunk('editShopOrder', async ({ shop_id, order_id }) => {
    const response = await api.get(`/shops/${shop_id}/orders/${order_id}`);
    return response.data.data.order;
})

export const viewShopOrder = createAsyncThunk('viewShopOrder', async ({ shop_id, order_id }) => {
    const response = await api.get(`/shops/${shop_id}/orders/${order_id}`);
    return response.data.data.order;
})

export const addUserShop = createAsyncThunk('addUserShop', async (formData) => {
    try {
        const response = await api.post(`/shops`, formData);
        return response.data.data;
    } catch (error) {
        console.log("check error fff", error.response.data);
        return error.response.data;
    }
});

export const updateShop = createAsyncThunk('updateShop', async ({ id, formData }) => {
    try {
        const response = await api.post(`/shops/${id}`, formData);
        return response.data.data.shop;
    } catch (error) {
        console.log("check update shop errors", error.response.data);
        return error.response.data;
    }
});


export const updateShopOrder = createAsyncThunk('updateShopOrder', async ({ order_id, data }) => {
    try {
        const response = await api.post(`/orders/${order_id}`, data);
        return response.data.data.shop.data;
    } catch (error) {
        return error.response.data.errors;
    }
});


export const reportShops = createAsyncThunk('reportShops', async (id) => {
    const response = await api.get(`/coupons/shops`);
    console.log("test shops add time", response.data.data.shops)
    return response.data.data.shops;
})


export const AddItem = createAsyncThunk('AddItem', async ({id,data}) => {
    const resp = await api.post(`/orderitems/${id}`,data);
    console.log("additem",   resp.data);
    return resp.data;
});


const shopsSlice = createSlice({
    name: "shops",
    initialState: {
        data: null,
        shops: [],
        shopOrders: [],
        assignOrder: [],
        orderItems: [],
        total: 0,
        shopOrdersTotal: 0,
        errors: null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchShops.fulfilled, (state, action) => {
            state.owner = action.payload
            state.total = action.payload.total;
            state.errors = null;
        })


        builder.addCase(reportShops.fulfilled, (state, action) => {
            state.shops = action.payload;
        })



        builder.addCase(shopOrders.fulfilled, (state, action) => {
            console.log()
            state.shopOrders = action.payload
            if (action.payload == undefined) {
                state.shopOrdersTotal = 0;
            } else {
                state.shopOrdersTotal = action.payload.total;
            }
            // state.errors = null;
        })
        // builder.addCase(delOwner.fulfilled, (state, action) => {
        //     state.data = action.payload;
        // })
        // builder.addCase(addedOwner.fulfilled, (state, action) => {
        //     state.coupon = action.payload
        // })
        builder.addCase(AddItem.fulfilled, (state, action) => {
            state.assignOrder = action.payload
        })
        builder.addCase(editShop.fulfilled, (state, action) => {
            const shopData = action.payload;
            state.shops = shopData;
        })
        builder.addCase(updateShop.fulfilled, (state, action) => {
            const postedData = action.payload;
            state.shops = postedData;
        });
        builder.addCase(editShopOrder.fulfilled, (state, action) => {
            // const shopView = action.payload;
            state.editShoporder = action.payload;
        })
        builder.addCase(viewShopOrder.fulfilled, (state, action) => {
            // const shopView = action.payload;
            state.viewShoporder = action.payload;
        })
        builder.addCase(addUserShop.fulfilled, (state, action) => {
            state.shops = action.payload;
            state.errors = null;
        });
        builder.addCase(addUserShop.rejected, (state, action) => {
            state.errors = action.payload;
        });

        builder.addCase(updateShopOrder.fulfilled, (state, action) => {
            state.shops = action.payload;
            state.errors = null;
        });
        builder.addCase(updateShopOrder.rejected, (state, action) => {
            state.errors = action.payload;
        });
        // builder.addCase(shopOrders.fulfilled, (state, action) => {
        //     state.shops = action.payload;
        //     state.total = action.payload.total;
        // });
    },
});

export default shopsSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";

export const fetchRateList = createAsyncThunk('fetchRateList', async ({ page, search }) => {
    const resp = await api.get(`/ratelist?page=${page}&keyword=${search}`);
    console.log("testing ratelist", resp.data.data.rateLists.data)
    return resp.data.data.rateLists
});

export const delRateList = createAsyncThunk('delRateList', async (id) => {
    const resp = await api.delete(`/ratelist/${id}`);
    return resp.data;
})

export const addedRateList = createAsyncThunk('addedRateList', async (data) => {
    try {
        const resp = await api.post(`/ratelist`, data);
        return resp.data.data;
    } catch (error) {
        console.log()
        return error.response.data;
    }
});

export const editRateList = createAsyncThunk('editRateList', async (id) => {
    const response = await api.get(`/ratelist/${id}`);
    return response.data.data;
})

export const updateRateList = createAsyncThunk('updateRateList', async ({id, data}) => {
    try {
        const response = await api.post(`/ratelist/${id}`, data);
        return response.data.data;
    } catch (error) {
        return error.response.data;
    }
});


const rateListSlice = createSlice({
    name: "prices",
    initialState: {
        data: null,
        prices: [],
        per_page: 0,
        total: 0,
        errors: null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRateList.fulfilled, (state, action) => {
            state.prices = action.payload.data;
            state.total = action.payload.total;
            state.per_page = action.payload.per_page;
            state.errors = null;
        })
        builder.addCase(delRateList.fulfilled, (state, action) => {
            state.data = action.payload;
        })
        builder.addCase(addedRateList.fulfilled, (state, action) => {
                state.prices = action.payload;
                state.errors = null;
            })
        builder.addCase(addedRateList.rejected, (state, action) => {
            state.errors = action.payload;
        });
        builder.addCase(editRateList.fulfilled, (state, action) => {
            const priceData = action.payload;
            state.viewRateList = priceData;
        })
        builder.addCase(updateRateList.fulfilled, (state, action) => {
            state.prices = action.payload;
            state.errors = null;
        })
        builder.addCase(updateRateList.rejected, (state, action) => {
            state.errors = action.payload;
        });

    },
});

export default rateListSlice.reducer;
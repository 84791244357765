import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// import Login from './Pages/Login';
const Login = lazy(() => import('./Pages/Login'));
const report = lazy(() => import('./Reports/report'));
// import DashboardContent from './Pages/DashboardContent';
const DashboardContent = lazy(() => import('./Pages/DashboardContent'));
// import Delivery from './DelvieryMan/deliveryMan';
const Delivery = lazy(() => import('./DelvieryMan/deliveryMan'));
// import AddDeliveryMan from './DelvieryMan/AddDeliveryMan';
const AddDeliveryMan = lazy(() => import('./DelvieryMan/AddDeliveryMan'));
// import Form from './Pages/Form';
const Form = lazy(() => import('./Pages/Form'));
// import Signup from './Pages/Signup';
const Signup = lazy(() => import('./Pages/Signup'));
// import ViewOrders from './Orders/ViewOrders';
const ViewOrders = lazy(() => import('./Orders/ViewOrders'));
// import Orders from './Orders/Orders';
const Orders = lazy(() => import('./Orders/Orders'));
// import EditOrders from './Orders/EditOrders';
const EditOrders = lazy(() => import('./Orders/EditOrders'));
// import AssignOrder from './Orders/AssignOrder';
const AssignOrder = lazy(() => import('./Orders/AssignOrder'));
// import EditDeliveryMan from './DelvieryMan/EditDeliveryMan';
const EditDeliveryMan = lazy(() => import('./DelvieryMan/EditDeliveryMan'));
// import Owner from './Owners/Owner';
const Owner = lazy(() => import('./Owners/Owner'));
// import EditOwner from './Owners/EditOwner';
const EditOwner = lazy(() => import('./Owners/EditOwner'));
// import Protected from './Pages/Protected';
const Protected = lazy(() => import('./Pages/Protected'));
// import Shops from "./AdminShops/Shops";
const Shops = lazy(() => import('./AdminShops/Shops'));
// import AddShops from "./AdminShops/AddShops";
const AddShops = lazy(() => import('./AdminShops/AddShops'));
// import EditShop from "./AdminShops/EditShop";
const EditShop = lazy(() => import('./AdminShops/EditShop'));
// import Coupons from './Coupons/Coupons';
const Coupons = lazy(() => import('./Coupons/Coupons'));
// import AddCoupon from "./Coupons/AddCoupon";
const AddCoupon = lazy(() => import('./Coupons/AddCoupon'));
// import EditCoupon from "./Coupons/EditCoupon";
const EditCoupon = lazy(() => import('./Coupons/EditCoupon'));
// import ShopOrder from './AdminShops/ShopOrder';
const ShopOrder = lazy(() => import('./AdminShops/ShopOrder'));
// import ShopOrderViewDetail from './AdminShops/ShopOrderViewDetail';
const ShopOrderViewDetail = lazy(() => import('./AdminShops/ShopOrderViewDetail'));
// import DeliveryManView from './DelvieryMan/DeliveryManView';
const DeliveryManView = lazy(() => import('./DelvieryMan/DeliveryManView'));
// import ShopOrderEdit from './AdminShops/ShopOrderEdit';
const ShopOrderEdit = lazy(() => import('./AdminShops/ShopOrderEdit'));
// import Profile from './UserProfile/Profile';
const Profile = lazy(() => import('./UserProfile/Profile'));
// import Customers from './Customer/Customers';
const Customers = lazy(() => import('./Customer/Customers'));
// import CustomerView from './Customer/CustomerView';
const CustomerView = lazy(() => import('./Customer/CustomerView'));
// import CustomerEdit from './Customer/CustomerEdit';
const CustomerEdit = lazy(() => import('./Customer/CustomerEdit'));
// import CustomerOrders from './Customer/CustomerOrders';
const CustomerOrders = lazy(() => import('./Customer/CustomerOrders'));
// import CustomerOrdersEdit from './Customer/CustomerOrdersEdit';
const CustomerOrdersEdit = lazy(() => import('./Customer/CustomerOrdersEdit'));
// import CustomerOrdersView from './Customer/CustomerOrdersView';
const CustomerOrdersView = lazy(() => import('./Customer/CustomerOrdersView'));
// import ViewDelvieryManOrder from './DelvieryMan/ViewDeliveryManOrder';
const ViewDelvieryManOrder = lazy(() => import('./DelvieryMan/ViewDeliveryManOrder'));
// import AdminLayout from './Layouts/AdminLayout';
const AdminLayout = lazy(() => import('./Layouts/AdminLayout'));
const DeliveryRateList = lazy(() => import('./RateList/DeliveryRateList'));
const AddDeliveryRate = lazy(() => import('./RateList/AddDeliveryRate'));
const EditDeliveryRate = lazy(() => import('./RateList/EditDeliveryRate'));

function App() {
  const notify = () => toast("Wow so easy!");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Suspense fallbacK="Loading...">
            <Login />
          </Suspense>} />

          <Route path='/' element={<Suspense fallback={<div className='text-center pt-5 h1'>Please wait...</div>}>
            <AdminLayout />
          </Suspense>}>
            <Route path='/dashboard_content' element={<Protected Component={DashboardContent} />} />

            <Route path='/orders' element={<Protected Component={Orders} />} />
            <Route path="/report" element={<Protected Component={report} />} />

            <Route path='/deliverymen' element={<Protected Component={Delivery} />} />
            <Route path='/adddeliveryman' element={<Protected Component={AddDeliveryMan} />} />
            <Route path='/deliveryman/:id/edit' element={<Protected Component={EditDeliveryMan} />} />
            <Route path='/deliveryman/:id/orders' element={<Protected Component={DeliveryManView} />} />
            <Route path='/deliveryman/order/:id/view' element={<Protected Component={ViewDelvieryManOrder} />} />
            <Route path='/form' element={<Protected Component={Form} />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/orders/:id/edit' element={<Protected Component={EditOrders} />} />
            {/* <Route path='/orders/:id/view' element={<Protected Component={ViewOrders} />} /> */}
            <Route path='/orders/:id/view' element={<Protected Component={ViewOrders} />} />
            <Route path='/report/orders/:id/view' element={<Protected Component={ViewOrders} />} />
            <Route path='/assignorder/:id' element={<Protected Component={AssignOrder} />} />
            <Route path='/owners' element={<Protected Component={Owner} />} />
            <Route path='/owner/:id/edit' element={<Protected Component={EditOwner} />} />
            <Route path='/shops' element={<Protected Component={Shops} />} />
            <Route path='/addshops' element={<Protected Component={AddShops} />} />
            <Route path='/shop/:id/edit' element={<Protected Component={EditShop} />} />
            <Route path='/coupons' element={<Protected Component={Coupons} />} />
            <Route path='/addcoupon' element={<Protected Component={AddCoupon} />} />
            <Route path='/coupon/:id/edit' element={<Protected Component={EditCoupon} />} />
            <Route path='/shop/:shop_id/orders' element={<Protected Component={ShopOrder} />} />
            {/* <Route path='/shoporderviewdetail/:id/:id' element={<Protected Component={ShopOrderViewDetail} />} /> */}
            {/* <Route path='/shoporderviewdetail/:id/:id' element={<Protected Component={ShopOrderViewDetail} />} /> */}
            <Route path='/shop/:shop_id/order/:order_id/view' element={<Protected Component={ShopOrderViewDetail} />} />
            <Route path='/shop/:shop_id/order/:order_id/edit' element={<Protected Component={ShopOrderEdit} />} />
            {/* <Route path='/userfile' element={<Protected Component={UserFile} />} /> */}
            <Route path='/profile' element={<Protected Component={Profile} />} />
            <Route path='/customers' element={<Protected Component={Customers} />} />
            <Route path='/customer/:id/view' element={<Protected Component={CustomerView} />} />
            <Route path='/customer/:id/edit' element={<Protected Component={CustomerEdit} />} />
            <Route path='/customer/:customer_id/orders' element={<Protected Component={CustomerOrders} />} />
            <Route path='/customer/orders/:id/edit' element={<Protected Component={CustomerOrdersEdit} />} />
            <Route path='/customer/orders/:id/view' element={<Protected Component={CustomerOrdersView} />} />
            <Route path='/deliveryratelist' element={<Protected Component={DeliveryRateList} />} />
            <Route path='/adddeliveryrate' element={<Protected Component={AddDeliveryRate} />} />
            <Route path='/deliveryrate/:id/edit' element={<Protected Component={EditDeliveryRate} />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;

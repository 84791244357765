import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";

export const fetchDeliveries = createAsyncThunk('fetchDeliveries', async ({ page, search }) => {
    const resp = await api.get(`/deliverymens?page=${page}&keyword=${search}`);
    console.log("test", resp.data.data.deliveryMens.data);
    return resp.data.data.deliveryMens;
});

export const delDelivery = createAsyncThunk('delDelivery', async (id) => {
    const resp = await api.delete(`/deliverymens/${id}`);
    console.log("test", resp.data.data.deliveryMens.data);
    return resp.data;
})

export const addedDelivery = createAsyncThunk('addedDelivery', async (formData) => {
    try {
        const resp = await api.post(`/deliverymens`, formData);
        return resp.data.data;
    } catch (error) {
        console.log()
        return error.response.data;
    }
});

export const editDelivery = createAsyncThunk('editDelivery', async (id) => {
    const response = await api.get(`/deliverymens/${id}`);
    return response.data.data.deliveryMen;
})
export const viewDelivery = createAsyncThunk('viewDelivery', async (id) => {
    const response = await api.get(`/deliverymens/${id}`);
    return response.data.data.deliveryMens;
})

export const updateDelivery = createAsyncThunk('updateDelivery', async ({ id, formData }) => {
    try {
        const resp = await api.post(`/deliverymens/${id}`, formData);
        return resp.data.data;
    } catch (error) {
        console.log()
        return error.response.data;
    }
});

export const DeliveryOrders = createAsyncThunk('DeliveryOrders', async ({ id, page, search, searchStatus }) => {
    const resp = await api.get(`/deliverymens/${id}/orders?page=${page}&keyword=${search}&status_name=${searchStatus}`);
    console.log("test", resp.data.data);
    return resp.data.data.orders;
});


const deliverymanSlice = createSlice({
    name: "deliveryman",
    initialState: {
        data: null,
        delivery: [],
        deliveryOrders: [],
        errors: null,
        total: 0,
        deliveryOrdersTotal: 0,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDeliveries.fulfilled, (state, action) => {
            state.delivery = action.payload
            state.errors = null;
        })
        builder.addCase(DeliveryOrders.fulfilled, (state, action) => {
            state.deliveryOrders = action.payload
            console.log(state.deliveryOrders, 'tested');
            if (action.payload == undefined) {
                state.deliveryOrdersTotal = 0;
            } else {
                state.deliveryOrdersTotal = action.payload.total;
            }
        })
        builder.addCase(delDelivery.fulfilled, (state, action) => {
            state.delivery = action.payload
        })
        builder.addCase(addedDelivery.fulfilled, (state, action) => {
            state.delivery = action.payload
            state.errors = null;
        })
        builder.addCase(addedDelivery.rejected, (state, action) => {
            state.errors = action.payload;
        });
        builder.addCase(editDelivery.fulfilled, (state, action) => {
            const deliveryData = action.payload;
            state.delivery = deliveryData;
        })
        builder.addCase(viewDelivery.fulfilled, (state, action) => {
            const deliveryData = action.payload;
            state.delivery = deliveryData;
        })
        builder.addCase(updateDelivery.fulfilled, (state, action) => {
            state.delivery = action.payload;
            // console.log(postedData, 'posted data');
        });
        builder.addCase(updateDelivery.rejected, (state, action) => {
            state.errors = action.payload;
        });
    },
});

export default deliverymanSlice.reducer;
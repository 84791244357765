import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";


export const reportShops = createAsyncThunk('reportShops', async (id) => {
    const response = await api.get(`/coupons/shops`);
    return response.data.data.shops;
})

const reportSlice = createSlice({
    name: "shops",
    initialState: {
        data: null,
        report: [],
        total: 0,
        errors: null,
      },
    extraReducers: (builder) => {
        builder.addCase(reportShops.fulfilled, (state, action) => {
            state.report = action.payload;
        })
    },
});
export default reportSlice.reducer;

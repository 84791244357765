import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../customAxios";

export const fetchOrder = createAsyncThunk('fetchOrder', async ({ page, search, findStatus }) => {
    const resp = await api.get(`/orders?page=${page}&keyword=${search}&status_name=${findStatus}`);
    console.log("fetch order data", resp.data.data);
    return resp.data.data.orders;
});

export const AssignOrders = createAsyncThunk('AssignOrders', async () => {
    const resp = await api.get("/deliverymens");
    return resp.data.data.deliveryMens;
});


export const AddItem = createAsyncThunk('AddItem', async ({id,data}) => {
    const resp = await api.post(`/orderitems/${id}`,data);
    console.log("additem",   resp.data);
    return resp.data;
});

export const viewOrder = createAsyncThunk('viewOrder', async (id) => {
    const resp = await api.get(`/orders/${id}`);
    console.log("test view order", resp.data.data.order);
    return resp.data.data.order;
});


// export const editOrder = createAsyncThunk('editOrder', async (id) => {
//     const resp = await api.get(`/orders/${id}`);
//     return resp.data.data;
// });

export const updateOrder = createAsyncThunk('updateOrder', async ({ id, data }) => {
    console.log(id, 'post id')
    try {
        const response = await api.post(`/orders/${id}`, data);
        console.log("a raha hai", response.data.data)
        return response.data.data;
    } catch (error) {
        console.log("update order error", error.response.data)
        return error.response.data;
    }
});

export const postAssignOrder = createAsyncThunk('postAssignOrder', async (data) => {
        const response = await api.post("/order/assign", data);
        console.log("post assign", response.data.data.delivery);
        return response.data.data.delivery;
});


// export const updateCustomerorder = createAsyncThunk('updateCustomerorder', async ({ id, data }) => {
//     try {
//         const response = await api.post(`/customers/${id}/orders`, data);
//         console.log("updateCustomerorder 3", response.data)
//         return response.data.data.customer.data;
//     } catch (error) {
//         console.log("error", error.response.data.errors)
//         return error.response.data.errors;
//     }
// });

const orderSlice = createSlice({
    name: "orders",
    initialState: {
        data: null,
        order: [],
        orderItems: [],
        assignOrder: [],
        total: 0,
        errors: null
    },

    extraReducers: (builder) => {
        builder.addCase(fetchOrder.fulfilled, (state, action) => {
            console.log(action.payload, 'addcase');
            state.order = action.payload
            // state.shopOrders = action.payload
            if (action.payload == undefined) {
                state.total = 0;
            } else {
                state.total = action.payload.total;
                console.log("total slice", state.total)
            }
        })
        // builder.addCase(editOrder.fulfilled, (state, action) => {
        //     state.order = action.payload.order;
        //     state.orderItems = action.payload.orderItems;
        // })
        builder.addCase(updateOrder.fulfilled, (state, action) => {
            state.order = action.payload;
            state.errors = null;
        });
        builder.addCase(updateOrder.rejected, (state, action) => {
            state.errors = action.payload;
        });
        builder.addCase(viewOrder.fulfilled, (state, action) => {
            state.order = action.payload;
        })
        builder.addCase(AssignOrders.fulfilled, (state, action) => {
            state.assignOrder = action.payload
        })
        builder.addCase(AddItem.fulfilled, (state, action) => {
            state.assignOrder = action.payload
        })
        builder.addCase(postAssignOrder.fulfilled, (state, action) => {
            state.assignOrder = action.payload
            state.errors = null;
        })
        builder.addCase(postAssignOrder.rejected, (state, action) => {
            state.errors = action.payload;
            console.log("reject errors", postAssignOrder);
        });
    },
});


export default orderSlice.reducer;